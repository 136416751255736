import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"

import Img from "components/img"

import CardBig from "pages/Home/components/common/CardBig"

import usePortability from "./hooks"

import "./style.scss"

const Portability = () => {
  const { isMobile, trackClick } = usePortability()

  return (
    <section className="portability" id="portability">
      <div className="portability__container">
        <div className="portability__infoWrapper">
          <div className="portability__cardContainer">
            <CardBig
              icon="consorcio-imobiliario"
              title="portabilidade"
              content="Agora você pode trazer seu financiamento de outro banco para o Itaú. Para fazer a contratação, é simples, fale com o seu gerente de relacionamento e escolha a linha de crédito que melhor combina com seu perfil."
            />
          </div>

          <If
            condition={isMobile}
            renderIf={
              <div className="portability__picture">
                <Img
                  file="lp-imobi-3"
                  alt="Mulher utilizando o celular"
                  className="portability__image"
                  data-aos="fade-left"
                />
              </div>
            }
          />
        </div>

        <If
          condition={!isMobile}
          renderIf={
            <div className="portability__imageContainer">
              <Img
                file="lp-imobi-3"
                alt="Mulher utilizando o celular"
                className="portability__image"
                data-aos="fade-left"
              />
            </div>
          }
        />
      </div>
    </section>
  )
}

export default Portability
